import { FC } from 'react'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { Container } from '@ucheba/ui/components/Container/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import Image from 'next/image'
import Img1 from '../assets/IndexPageAboutTeachers/img__about-teachers__1.png'
import Img2 from '../assets/IndexPageAboutTeachers/img__about-teachers__2.png'
import Img3 from '../assets/IndexPageAboutTeachers/img__about-teachers__3.png'
import Img4 from '../assets/IndexPageAboutTeachers/img__about-teachers__4.png'

const Block = styled.section`
  background-color: ${sv.color.silver};
  padding: 80px 0;
`

const IndexPageAboutTeachers: FC = () => {
  return (
    <Block>
      <Container>
        <Grid>
          <GridCol xs={12}>
            <Grid>
              <GridCol width='50%'>
                <Image src={Img1} alt='' width={80} height={80} />

                <Spacing spacing='xxsmall' />

                <Text html={false} fontSize='h4' fontWeight='bold' lineHeight='h4'>
                  Планируйте и проводите
                  <br /> онлайн-занятия
                </Text>
              </GridCol>

              <GridCol width='50%'>
                <Image src={Img2} alt='' width={80} height={80} />

                <Spacing spacing='xxsmall' />

                <Text html={false} fontSize='h4' fontWeight='bold' lineHeight='h4'>
                  Составляйте
                  <br />и проверяйте работы
                </Text>
              </GridCol>

              <GridCol width='50%'>
                <Image src={Img3} alt='' width={80} height={80} />

                <Spacing spacing='xxsmall' />

                <Text html={false} fontSize='h4' fontWeight='bold' lineHeight='h4'>
                  Используйте банк
                  <br />
                  готовых заданий
                </Text>
              </GridCol>

              <GridCol width='50%'>
                <Image src={Img4} alt='' width={80} height={80} />

                <Spacing spacing='xxsmall' />

                <Text html={false} fontSize='h4' fontWeight='bold' lineHeight='h4'>
                  Контролируйте
                  <br />
                  процесс обучения
                </Text>
              </GridCol>
            </Grid>
          </GridCol>

          <GridCol xs={12}>
            <Heading h4='Репетиторам' spacing='xsmall' />

            <Heading
              h1='Новая удобная платформа для проведения уроков'
              as='h2'
              spacing='xsmall'
            />

            <Text fontSize='large' lineHeight='large'>
              Вместо зума, гугл-таблиц и форм, квизлетов, решуЕГЭ и кучи других
              разрозненных инструментов.
            </Text>

            <Spacing spacing='small' />

            <Button
              href='/for-teachers?utm_source=ucheba&utm_medium=mainpage&utm_campaign=repetitors_platform'
              color='blue'
            >
              Узнать больше
            </Button>
          </GridCol>
        </Grid>
      </Container>
    </Block>
  )
}

export default IndexPageAboutTeachers
