import React from 'react'
import { NextPage } from 'next'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import { Container } from '@ucheba/ui/components/Container/desktop'
import { Dialog } from '@ucheba/ui/components/Dialog/desktop'
import { useDialog } from '@ucheba/ui/components/Dialog/bll'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Banner } from '../../../components/Banner/desktop'
import { IIndexPageProps } from '../types'
import IndexPageHero from './IndexPageHero'
import IndexPageLogotypes from './IndexPageLogotypes'
import IndexPageServices from './IndexPageServices'
import IndexPageSpecialties from './IndexPageSpecialties'
import IndexPageRatings from './IndexPageRatings'
import IndexPageFooter from './IndexPageFooter'
import IndexPageChooseInstitutionType from './IndexPageChooseInstitutionType'
import { useIndexPageCore } from '../bll'
import IndexPageEvents from './indexPageEvents'
import IndexPageArticles from './IndexPageArticles'
import IndexPageAboutTeachers from './IndexPageAboutTeachers'

/** Главная страница */
const IndexPage: NextPage<IIndexPageProps> = (props) => {
  const { content } = props

  const {
    hero,
    institutionLogotypes,
    services,
    specialties,
    ratings,
    footer,
    chooseInstitutionTypeDialogContent,
  } = content

  const chooseInstitutionTypeDialog = useDialog('choose-institution-type')

  const { institutionType, setInstitutionType } = useIndexPageCore()

  return (
    <div>
      <IndexPageHero
        {...hero}
        institutionType={institutionType}
        chooseInstitutionTypeDialog={chooseInstitutionTypeDialog}
      />

      <IndexPageLogotypes {...institutionLogotypes} />

      <IndexPageServices {...services} />

      <IndexPageSpecialties {...specialties} institutionType={institutionType} />

      <IndexPageRatings {...ratings} />

      <Container>
        <Grid>
          <GridCol uxs={18}>
            <IndexPageArticles />

            <IndexPageEvents />
          </GridCol>

          <GridCol uxs={6}>
            <Spacing spacing='uxlarge' />

            <div style={{ position: 'sticky', top: 0, paddingBottom: '30px' }}>
              <Banner id={3} />
            </div>
          </GridCol>
        </Grid>
      </Container>

      <IndexPageAboutTeachers />

      <IndexPageFooter {...footer} />

      <Dialog
        size='xsmall'
        overflow='visible'
        open={chooseInstitutionTypeDialog.isActive}
        onClose={chooseInstitutionTypeDialog.closeDialog}
      >
        <IndexPageChooseInstitutionType
          {...chooseInstitutionTypeDialog}
          {...chooseInstitutionTypeDialogContent}
          setInstitutionType={setInstitutionType}
        />
      </Dialog>

      <Banner id={6} />
      <Banner id={14} />
    </div>
  )
}

export default IndexPage
