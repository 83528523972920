import React from 'react'
import { NextPage } from 'next'
import { Container } from '@ucheba/ui/components/Container/desktop'
import styled from 'styled-components'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { Grid } from '@ucheba/ui/components/Grid/desktop'
import { GridCol } from '@ucheba/ui/components/Grid/desktop/GridCol'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { sm } from '@ucheba/utils/helpers/styles/mixins'
import { useOnlyClientRendering } from '@ucheba/utils/hooks/useOnlyClientRendering'
import { EInstitutionTypeValue, IIndexPageProps, TInstitutionTypeValue } from '../types'
import SC from '../base/IndexPageHero.styles'
import { headerHeight } from '../../../components/Header/constants'
import desktopLargeImage from '../assets/img__hero-bg_desktop-large.png'
import desktopSmallImage from '../assets/img__hero-bg_desktop-small.png'
import { useChooseInstitutionsHref } from '../bll'
import {
  useChooseLocationDialogCore,
  useLocationText,
} from '../../../components/ChooseLocation/bll'

const {
  Block: BlockSC,
  Icon: IconSC,
  Link,
  LinkContent,
  InstitutionText: InstitutionTextSC,
  ProgramsText: ProgramsTextSC,
  StudentsText: StudentsTextSC,
} = SC

const Block = styled(BlockSC)`
  padding-bottom: ${sv.spacing.uxxlarge};
  margin-top: -${headerHeight.desktop}px;
  padding-top: 136px;
  background-repeat: no-repeat;
  background-position: center 107px;
  position: relative;

  ${sm.breakpoint.lgAndUp`
    background-image: url(${desktopLargeImage});
    background-size: 1590px;
  `};

  ${sm.breakpoint.lgAndDown`
    background-image: url(${desktopSmallImage});
    background-size: 1200px;
  `};
`

const InstitutionText = styled(InstitutionTextSC)`
  ${sm.breakpoint.lgAndUp`
    left: 254px;
    top: 21px;
  `};

  ${sm.breakpoint.lgAndDown`
    left: 174px;
    top: 21px;
  `};
`

const StudentsText = styled(ProgramsTextSC)`
  ${sm.breakpoint.lgAndUp`
    right: 404px;
    top: 167px;
  `};

  ${sm.breakpoint.lgAndDown`
    right: 394px;
    top: 167px;
  `};
`

const ProgramsText = styled(StudentsTextSC)`
  ${sm.breakpoint.lgAndUp`
    left: 254px;
    top: 167px;
  `};

  ${sm.breakpoint.lgAndDown`
    left: 174px;
    top: 167px;
  `};
`

const StyledGridCol = styled.div`
  position: relative;
`

const Icon = styled(IconSC)`
  margin-bottom: 6px;
`

const IndexPage: NextPage<IIndexPageProps['content']['hero']> = (props) => {
  const {
    buttonText,
    title,
    institutions,
    programs,
    students,
    chooseInstitutionTypeDialog,
    institutionType,
  } = props

  const { baseText } = useLocationText()

  const href = useChooseInstitutionsHref(institutionType.value as TInstitutionTypeValue)

  const isClient = useOnlyClientRendering()

  const { extendedHeroOpenDialog } = useChooseLocationDialogCore()

  return (
    <Block>
      <Container>
        <Grid justifyContent='space-between'>
          <GridCol uxs={12}>
            <Heading h1={title} color='white' spacing='null' />

            <Link>
              <Text
                as='span'
                fontWeight='extrabold'
                fontSize='h1'
                lineHeight='h1'
                color='white'
              >
                в
              </Text>

              <LinkContent onClick={chooseInstitutionTypeDialog?.openDialog}>
                <Text
                  as='span'
                  fontWeight='extrabold'
                  fontSize='h1'
                  lineHeight='h1'
                  color='gold'
                >
                  {institutionType?.visibleText}
                </Text>

                <Icon color='gold' />
              </LinkContent>
            </Link>

            <Link>
              <Text
                as='span'
                fontWeight='extrabold'
                fontSize='h1'
                lineHeight='h1'
                color='white'
              >
                в
              </Text>

              <LinkContent onClick={extendedHeroOpenDialog}>
                <Text
                  as='span'
                  fontWeight='extrabold'
                  fontSize='h1'
                  lineHeight='h1'
                  color='flamingo'
                >
                  {baseText}
                </Text>

                <Icon color='flamingo' />
              </LinkContent>
            </Link>

            <Spacing spacing='small' />

            {isClient && (
              <Button href={href} color='gold'>
                {institutionType.value === EInstitutionTypeValue.vuz
                  ? buttonText?.institution
                  : buttonText?.college}
              </Button>
            )}
          </GridCol>

          <StyledGridCol as={GridCol as any} uxs={12}>
            <InstitutionText>
              <Text fontWeight='extrabold' color='white' fontSize='h3'>
                {institutions.count}
              </Text>

              <Spacing spacing='usmall' />

              <Text uppercase fontSize='xsmall1' color='white' lineHeight='xsmall1'>
                {institutions.text}
              </Text>
            </InstitutionText>

            <StudentsText>
              <Text fontWeight='extrabold' color='white' fontSize='h3'>
                {students.count}
              </Text>

              <Spacing spacing='usmall' />

              <Text uppercase fontSize='xsmall1' color='white' lineHeight='xsmall1'>
                {students.text}
              </Text>
            </StudentsText>

            <ProgramsText>
              <Text fontWeight='extrabold' color='white' fontSize='h3'>
                {programs.count}
              </Text>

              <Spacing spacing='usmall' />

              <Text uppercase fontSize='xsmall1' color='white' lineHeight='xsmall1'>
                {programs.text}
              </Text>
            </ProgramsText>
          </StyledGridCol>
        </Grid>
      </Container>
    </Block>
  )
}

export default IndexPage
